<template>
  <div>
    <h3 class="underlined">Search Results</h3>
    <div class="card">
      <div class="card-body">
        <h5>
          Your search - {{ this.$route.query.term }} - did not match any
          documents.
        </h5>
        <p>Suggestions:</p>
        <ul class="text-muted">
          <li>Make sure that all words are spelled correctly.</li>
          <li>Try different keywords.</li>
          <li>Try more general keywords.</li>
          <li>Try fewer keywords.</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
